const api = require('api/ReportSubmissionsAPI');

// common libs
const Projects = require('models/Projects');
const PageEvents = require('events/PageEvents');
const ProjectRoutes = require('routes/ProjectRoutes');

module.exports = Backbone.Router.extend({
  routes: {
    'org/:orgId/reports/overview': 'reportsOverview',
    'org/:orgId/reports/reportCycle/:reportCycle/status/:status': 'status',
    'org/:orgId/reports/reportCycle/:reportCycle/status/:status/project/:projectId':
      'overviewProject',

    'project/:projectId/reports': 'projectReports',

    'org/:orgId/reports/admin/templates': 'adminTemplates',
    'org/:orgId/reports/admin/reportPeriods': 'adminReportPeriods',
    'org/:orgId/reports/admin/reportSchedule': 'adminReportSchedule'
  },

  reportsOverview(orgId) {
    const that = this;

    require.ensure([], function() {
      const ReportsOverview = require('views/reports/ReportsOverviewView');
      import(/* webpackChunkName: "Chart.js" */ 'chart.js');

      const reportsOverview = new ReportsOverview({ groupId: orgId });
      that.routeToView(reportsOverview, orgId, 'reports-overview');
    }); // require.ensure
  },

  status(orgId, reportCycle, status) {
    const that = this;

    require.ensure([], function() {
      require('autosize/autosize.min');
      const ReportsProjectListView = require('views/reports/ReportsProjectListView');

      let selected = 'progress-reports';

      if (reportCycle === 'final') {
        selected = 'final-reports';
      }

      const url = api.getProjectsList(orgId, user.id, reportCycle, status);

      async function getAccessCode() {
        const response = await fetch('/api/login/get-access-code');

        const text = await response.text();
        return text;
      }

      let accessCode;
      getAccessCode().then(text => {
        accessCode = text;
      });

      $.get(url, function(data) {
        const projectsListView = new ReportsProjectListView({
          orgId,
          reportCycle,
          status,
          code: accessCode,
          label: data.reportCycleLabel,
          overview: data.overview,
          projects: data.projects,
          reportCycles: data.reportCycles
        });

        that.routeToView(projectsListView, orgId, selected);
      });
    });
  },
  overviewProject(orgId, reportCycle, status, projectId) {
    const that = this;

    require.ensure([], function() {
      require('autosize/autosize.min');
      const ReportsView = require('views/reports/ReportsView');

      const url = api.getProjectName(orgId, projectId);
      $.get(url).done(function(data) {
        let reportCycleLabel = moment(reportCycle)
          .utc()
          .format('MMMM D, YYYY');

        if (reportCycle === 'final') {
          reportCycleLabel = 'Final Report';
        }
        const reportsView = new ReportsView({
          orgId,
          projectId,
          status,
          reportCycle,
          projectName: data.name,
          reportCycleLabel,
          backbutton: true,
          orgOwner: data.isOwner
        });
        let selected = '-reports';
        if (reportCycle === 'final') {
          selected = `final${selected}`;
        } else {
          selected = `progress${selected}`;
        }

        that.routeToView(reportsView, orgId, selected);
      });
    });
  },

  adminTemplates(orgId) {
    const that = this;

    require.ensure([], function() {
      const AddTemplateView = require('views/reports/admin/AddTemplateView');

      const view = new AddTemplateView({
        orgId
      });
      that.routeToView(view, orgId);
    });
  },
  adminReportPeriods(orgId) {
    const that = this;
    require.ensure([], function() {
      const ProjectReportPeriods = require('views/reports/admin/ReportPeriodsView');

      const projectReportPeriods = new ProjectReportPeriods({
        orgId,
        reportsUrl: `//${window.location.host}/reports/`
      });
      that.routeToView(projectReportPeriods, orgId);
    });
  },
  adminReportSchedule(orgId) {
    const that = this;

    require.ensure([], function() {
      const ReportCycleSchedule = require('views/reports/admin/ReportCycleScheduleView');

      const reportCycleSchedule = new ReportCycleSchedule({
        orgId
      });
      that.routeToView(reportCycleSchedule, orgId);
    });
  },

  routeToView(newView, orgId, selected) {
    log.debug('selected: %s', selected);

    require.ensure([], function() {
      const OrgView = require('views/organizations/OrgView');
      const ReportsWrapperView = require('views/reports/ReportsWrapperView');

      const { currentView } = app.m.getRegion('main');

      if (currentView instanceof OrgView) {
        OrgView.updateModel(orgId, 'reports');

        const mainView = currentView.getRegion('main').currentView;

        if (mainView instanceof ReportsWrapperView) {
          mainView.getRegion('reportsMain').show(newView);

          mainView.selectTab(selected);
        } else {
          const wrapperView = new ReportsWrapperView({
            orgId,
            selected
          });

          const mainRegion = currentView.getRegion('main');
          mainRegion.show(wrapperView);
          mainRegion.currentView.getRegion('reportsMain').show(newView);
        }
      } else {
        const view = new OrgView({
          orgId,
          selected: 'reports'
        });

        Backbone.Events.listenToOnce(view, 'show', function() {
          const wrapperView = new ReportsWrapperView({
            orgId,
            selected
          });

          view.getRegion('main').show(wrapperView);
        });

        const mainRegion = app.m.getRegion('main');
        mainRegion.show(view);

        const reportsView = mainRegion.currentView.getRegion('main')
          .currentView;
        reportsView.getRegion('reportsMain').show(newView);
      }
    });
  },
  projectReports(projectId) {
    require.ensure([], function() {
      const ProjectView = require('views/projects/ProjectView');
      const ProjectTitleView = require('views/projects/ProjectTitleView');
      const ProjectReportsView = require('views/reports/ProjectReportsView');

      Projects.load(projectId).done(function(project) {
        app.events.trigger(PageEvents.setTitle, project.get('name'));

        const region = app.m.getRegion('main');

        let projectView = region.currentView;

        if (
          !(region.currentView instanceof ProjectView) ||
          projectView.model.id !== project.id
        ) {
          projectView = new ProjectView({
            model: project
          });
          region.show(projectView);
          projectView.header.show(
            new ProjectTitleView({
              model: project,
              selected: 'reports'
            })
          );
        }

        app.events.trigger(PageEvents.changed, 'reports');

        const url = api.getProjectReportGroup(projectId);

        let editable = false;

        if (project.isEditable()) {
          editable = true;
        }

        $.get(url).done(function(data) {
          projectView.main.show(
            new ProjectReportsView({
              projectId,
              orgId: data.groupId,
              returnRoute: ProjectRoutes.files(projectId),
              projectName: project.get('name'),
              editable,
              orgOwner: data.isOrgOwner
            })
          );
        });
      });
    });
  }
});
