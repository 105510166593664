// UserAPI
module.exports = {
  getProfile(name) {
    return Mustache.render('/api/user/{{name}}/profile', {
      name
    });
  },
  getPicture(id) {
    return Mustache.render('/user/{{id}}/pic', {
      id
    });
  },
  getOriginalBackground(id) {
    return Mustache.render('/api/user/{{id}}/background/original', {
      id
    });
  },
  cropBackground(id) {
    return Mustache.render('/api/user/{{id}}/background/crop', {
      id
    });
  },
  getEditProfile(name) {
    return Mustache.render('/api/user/{{name}}/profile/edit', {
      name
    });
  },
  getProfileAddress(name, id) {
    return Mustache.render('/api/user/{{name}}/address/{{id}}', {
      name,
      id
    });
  },
  getProfilePosition(name, id) {
    return Mustache.render('/api/user/{{name}}/position/{{id}}', {
      name,
      id
    });
  },
  getInfo(id) {
    return Mustache.render('/api/user/{{id}}/info', {
      id
    });
  },
  isNameValid() {
    return '/api/user/name/valid';
  },
  isEmailValid() {
    return '/api/user/email/valid';
  },
  sendFeedback() {
    return '/api/feedback';
  },
  resetPassword() {
    return '/login/changePassword';
  },
  getNameByEmail() {
    return '/api/user/email/name';
  }
};
